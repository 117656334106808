.c-modal-calendar {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 102;
  display: none;
}
.c-modal-calendar.open {
  display: flex;
}
.c-modal-calendar__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(0 0 0 / 0.2);
  cursor: pointer;
}
.c-modal-calendar__frame {
  width: calc(100% - 32px);
  max-width: 640px;
  background: #fff;
  position: relative;
}
.c-modal-calendar__frame__close {
  width: 40px;
  height: 40px;
  position: absolute;
  top: -40px;
  right: 0;
}
.c-modal-calendar__frame__close::before,
.c-modal-calendar__frame__close::after {
  content: '';
  display: block;
  width: 28px;
  height: 3px;
  background: #fff;
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 14px);
  cursor: pointer;
}
.c-modal-calendar__frame__close::before {
  transform: rotate(45deg);
}
.c-modal-calendar__frame__close::after {
  transform: rotate(-45deg);
}
.c-modal-calendar__frame__body {
  padding: 16px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.c-modal-calendar__frame__body .widget-calendar__promotion__list__item + li {
  margin-top: 40px;
}
.c-modal-calendar__frame__body .widget-calendar__promotion__list__item__header p {
  white-space: initial;
}
