.title h1 {
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .title h1 {
    font-size: 14px;
  }
}

.c-header__notification_icon {
  margin-top: 16px;
  margin-right: 16px;
  padding-left: 16px;
}
